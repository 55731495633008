<template>
	<div class="navigations">
		<div class="navigations-mei">
			<div class="navigations-mei1" v-for="(item,index) in Navlist" :key="index">
				<router-link :to="item.path" class="router-link-as">
					<div class="navigations-mai-list-list-off">
						<div class="navigations-mai-list-list-off-img">
							<i class="iconfont" :class="item.NavIng" style="font-size:24px;"></i>
						</div>
						<div class="navigations-mai-list-list-off-txt">
							{{item.meta.title}}
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				Navlist: [],
				navNums: 0
			}
		},
		methods: {
			goBack() {
				////console.log('go back');
			}
		},
		computed: {
			newNav() {
				return this.$store.state.navNum
			}
		},
		watch: {
			newNav(nres) {
				this.Navlist = this.$router.options.routes[2].children[nres].children
			},
		},
		mounted() {
			this.navNums =
				this.Navlist = this.$router.options.routes[2].children[this.$store.state.navNum].children
		},
	}
</script>

<style lang="scss">
	.navigations {
		.navigations-mei {
			display: flex;
			align-items: center;
			padding: 10px;
			box-shadow: 5px 5px 10px -10px #000;
			.navigations-mei1 {
				.navigations-mai-list-list-off {
					display: flex;
					align-items: center;
					min-width: 80px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 5px 10px;
					.navigations-mai-list-list-off-img {
						color: #000;
						img {
							width: 100%;
							height: 100%;
						}
					}

					.navigations-mai-list-list-off-txt {
						font-size: 12px;
						transform: scale(.8);
						margin-top: 5px;
						color:#232428;
					}
				}
			}
		}

		img {
			width: 100%;
			height: auto;
		}
	}
</style>
